<template>
  <DataTable :resources="all_cloud_accounts" :striped-class="false">
    <template #actions>
      <h3 class="text-xl font-bold text-gray-80">
        {{ $t('cloud_account.modal.title') }}
      </h3>
    </template>
    <template #thead>
      <DataTableHead
        id="checkbox"
        :defaultOrderColumn="false"
        :disableOrder="true"
      >
        <label for="cloud-account-select-all" class="custom-checkbox">
          <input type="checkbox" id="cloud-account-select-all" v-model="selectAll" />
          {{ $t("activerecord.attributes.product.name") }}
          <span class="checkmark checkmark-sm"></span>
        </label>
      </DataTableHead>
      <DataTableHead
        v-for="thead in theads"
        :id="thead.id"
        :text="$t(thead.i18n_key)"
        :defaultOrderColumn="thead.defaultOrderColumn"
        :disableOrder="thead.disableOrder"
      >
      </DataTableHead>
    </template>
    <template #tbody-tr="{ resource }">
      <td>
        <label :for="`cloud-account-select-${resource.id}`" class="custom-checkbox">
          <input
            type="checkbox"
            :id="`cloud-account-select-${resource.id}`"
            disabled
            v-if="cloud_account_ids.includes(resource.id)"
            checked
          />
          <input
            type="checkbox"
            :id="`cloud-account-select-${resource.id}`"
            v-model="form.cloud_account_ids"
            :value="resource.id"
            v-else
          />
          {{ resource.product_name }}
          <span class="checkmark checkmark-sm"></span>
        </label>
      </td>
      <td>{{ resource.account_id }}</td>
      <td>{{ resource.organization_name }}</td>
      <td></td>
    </template>
  </DataTable>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  product: Object,
  cloud_account_ids: {
    type: Array,
    required: true,
  },
  all_cloud_accounts: {
    type: Array,
    required: true,
  },
});

const theads = [
  {
    id: "account_id",
    i18n_key: "activerecord.attributes.cloud_account.account_id",
    disableOrder: true,
  },
  {
    id: "company",
    i18n_key: "cloud_account.modal.company",
    disableOrder: true,
  },
  {
    id: "dealer",
    i18n_key: "cloud_account.modal.dealer",
    disableOrder: true,
  },
];

const form = useForm({
  cloud_account_ids: [],
});

const selectAll = ref(false);
const selectableCloudAccounts = props.all_cloud_accounts.filter(
  (cloud_account) => !props.cloud_account_ids.includes(cloud_account.id)
);

const emit = defineEmits(["submit"]);

onMounted(() => {
  if (form.cloud_account_ids.length === selectableCloudAccounts.length) {
    selectAll.value = true;
  } else if (form.cloud_account_ids.length > 0) {
    selectAll.value = false;
  } else {
    selectAll.value = false;
  }

  watch(selectAll, (newVal) => {
    if (newVal) {
      form.cloud_account_ids = selectableCloudAccounts.map((cloud_account) => cloud_account.id);
    } else {
      form.cloud_account_ids = [];
    }
  });

  watch(
    () => form.cloud_account_ids,
    (newVal) => {
      const checkedIds = newVal;
      const element = document.querySelector("#cloud-account-select-all");

      if (checkedIds.length === 0) {
        element.indeterminate = false;
        selectAll.value = false;
      } else if (checkedIds.length === selectableCloudAccounts.length) {
        element.indeterminate = false;
        selectAll.value = true;
      } else {
        element.indeterminate = true;
      }

      emit("submit", checkedIds);
    }
  );
});
</script>
