<template>
  <ConfirmModal
    :modal-cancel-text="$t('helpers.cancel')"
    :modal-submit-text="$t('helpers.confirm')"
    modal-submit-class="btn btn-sm btn-primary"
    :modal-title="$t('helpers.list_setting')"
    :modal-subtitle="$t('cloud_account.list_setting.select_max_9')"
    modal-size="lg"
    @submit="updateListSetting"
    class="btn btn-sm btn-primary btn-outline"
  >
    <template #sub-title>
      <p class="text-sm text-gray-80">
        {{ $t("cloud_account.list_setting.select_max", { max: max_columns }) }}
      </p>
    </template>
    <template #button>
      <IconSvg icon="menu" class="w-4 h-4 fill-primary-dark"></IconSvg>
      {{ $t("helpers.list_setting") }}
    </template>

    <div class="grid grid-cols-4 gap-10">
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.info") }}
        </h5>

        <label
          :for="`list_info_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.info"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`list_info_${column}`"
            v-model="selected_columns"
            :value="column"
            :class="{ 'selected-column': column !== 'account_id' }"
            :disabled="column === 'account_id'"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2">
          <h5 class="text-sm font-bold text-gray-80">
            {{ $t("cloud_account.index.modal.client_info") }}
          </h5>

          <label
            :for="`list_client_info_${column}`"
            class="custom-checkbox"
            v-for="column in categoryColumns.client_info"
            :key="column"
          >
            <input
              type="checkbox"
              :id="`list_client_info_${column}`"
              v-model="selected_columns"
              :value="column"
              class="selected-column"
            />
            {{ $t(`cloud_account.index.modal.${column}`) }}
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="flex flex-col gap-2">
          <h5 class="text-sm font-bold text-gray-80">
            {{ $t("cloud_account.index.modal.support_way") }}
          </h5>

          <label
            :for="`list_support_way_${column}`"
            class="custom-checkbox"
            v-for="column in categoryColumns.support_way"
            :key="column"
          >
            <input
              type="checkbox"
              :id="`list_support_way_${column}`"
              v-model="selected_columns"
              :value="column"
              class="selected-column"
            />
            {{ $t(`cloud_account.index.modal.${column}`) }}
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.client_business") }}
        </h5>

        <label
          :for="`list_client_business_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.client_business"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`list_client_business_${column}`"
            v-model="selected_columns"
            :value="column"
            class="selected-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">
          {{ $t("cloud_account.index.modal.dgc_cost") }}
        </h5>

        <label
          :for="`list_dgc_cost_${column}`"
          class="custom-checkbox"
          v-for="column in categoryColumns.dgc_cost"
          :key="column"
        >
          <input
            type="checkbox"
            :id="`list_dgc_cost_${column}`"
            v-model="selected_columns"
            :value="column"
            class="selected-column"
          />
          {{ $t(`cloud_account.index.modal.${column}`) }}
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </ConfirmModal>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { router } from "@inertiajs/vue3";
import { categoryColumns } from "./category_columns";
const props = defineProps({
  list_setting: Array,
  updateEndpoint: {
    type: String,
    default: "/cloud_accounts/list_setting",
  },
});


const max_columns = ref(9);

const selected_columns = ref(props.list_setting);
const updateListSetting = () => {
  // sort selected_columns by categoryColumns values
  const categoryColumnsFlat = Object.values(categoryColumns.value).flat();
  selected_columns.value.sort((a, b) => {
    return categoryColumnsFlat.indexOf(a) - categoryColumnsFlat.indexOf(b);
  });

  router.put(props.updateEndpoint, {
    list_setting: selected_columns.value,
  });
};

const toggleColumnDisabled = (selected_columns) => {
  if (selected_columns.length >= max_columns.value + 1) {
    document
      .querySelectorAll(".selected-column:not(:checked)")
      .forEach((column) => {
        column.disabled = true;
      });
  } else {
    document
      .querySelectorAll(".selected-column:not(:checked)")
      .forEach((column) => {
        column.disabled = false;
      });
  }
};

onMounted(() => {
  toggleColumnDisabled(selected_columns.value);
  watch(selected_columns, () => {
    toggleColumnDisabled(selected_columns.value);
  });
});
</script>
