<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <SubNav :sub-nav-list="props.sub_menu_lists"></SubNav>
        <Info
          @submit="modifyProduct"
          :product="form.product"
          :options_for_select="props.options_for_select"
          :errors="errors"
        />
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="true"
          :back-url="'/products'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import Info from "./_info.vue";

const props = defineProps({
  product: Object,
  options_for_select: Object,
  sub_menu_lists: Array,
  errors: {
    type: Object,
    default: {},
  },
});

const form = useForm({
  product: props.product,
});

const formSubmit = () => {
  form.post("/products");
};

const modifyProduct = (new_value) => {
  Object.assign(form.product, new_value);
};
</script>
