<template>
  <form @submit.prevent="formSubmit" autocomplete="off">
    <div class="card">
      <div class="card-body">
        <SubNav :sub-nav-list="props.sub_menu_lists"></SubNav>

        <Info
          @submit="modifyOrganization"
          :organization="form.organization"
          :options_for_select="props.options_for_select"
          :authenticity_token="props.authenticity_token"
          :errors="errors"
        />
      </div>
      <div class="card-footer">
        <FormAction
          :can-submit="validate(form.organization)"
          :delete-url="`/organizations/${props.organization.id}`"
          :has-modified="form.isDirty"
          :delete-target="props.organization.name"
          :delete-from="$t('activerecord.models.organization')"
          :back-url="'/organizations'"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import Info from "./_info.vue";
import { validate } from "./validate";

const props = defineProps({
  organization: Object,
  options_for_select: Object,
  sub_menu_lists: Array,
  authenticity_token: String,
  errors: {
    type: Object,
    default: {},
  },
});


const form = useForm({
  organization: props.organization,
});

const formSubmit = () => {
  delete form.organization.id;
  form.put(`/organizations/${props.organization.id}`);
};

const modifyOrganization = (new_value) => {
  Object.assign(form.organization, new_value);
};
</script>
