<template>
  <div class="*:py-5 divide-y">
    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-center col-span-1">
        <label :for="`special_discount_name_${props.special_discount.id}`" class="ml-3 required">
          {{ $t("activerecord.attributes.special_discount.name") }}
        </label>
      </dt>
      <dd class="flex items-center col-span-5">
        <input
          v-model="form.special_discount.name"
          type="text"
          class="input"
          :id="`special_discount_name_${props.special_discount.id}`"
        />
      </dd>
    </dl>
    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-start col-span-1">
        <label :for="`special_discount_conditions_${props.special_discount.id}`" class="ml-3">
          {{
            $t("activerecord.attributes.special_discount.conditions")
          }}
        </label>
      </dt>
      <dd class="flex flex-col items-start col-span-11 gap-2">
        <Condition
          v-for="(condition, index) in form.special_discount.conditions"
          :condition="condition"
          :index="index"
          :target_options="options_for_select.special_discount_targets"
          :operator_options="operatorOptions"
          @submit="modifyCondition"
          @removeCondition="removeCondition"
        />
        <button @click="addCondition" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="size-4"></IconSvg>
          {{
            $t("helpers.new", {
              model: $t(
                "activerecord.attributes.special_discount.conditions"
              ),
            })
          }}
        </button>
      </dd>
    </dl>

    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-center col-span-1">
        <label :for="`special_discount_category_${props.special_discount.id}`" class="ml-3 required">
          {{
            $t(
              "activerecord.attributes.special_discount.structure_config"
            )
          }}
        </label>
      </dt>
      <dd class="flex flex-col items-start col-span-11 gap-2">
        <div class="flex items-center gap-5">
          <multiselect
            v-model="form.special_discount.category"
            :options="props.options_for_select.special_discount_categories"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="!min-w-[auto] max-w-[max-content]"
            :id="`special_discount_category_${props.special_discount.id}`"
          >
            <template #singleLabel="props">
              {{ $t(`special_discount.category.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`special_discount.category.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>

          <label :for="`special_discount_structure_unified_${props.special_discount.id}`" class="custom-radio">
            <input
              type="radio"
              :id="`special_discount_structure_unified_${props.special_discount.id}`"
              v-model="form.special_discount.structure"
              value="unified"
            />
            {{ $t("special_discount.structure.unified") }}
            <span class="checkmark"></span>
          </label>

          <label :for="`special_discount_structure_stepping_${props.special_discount.id}`" class="custom-radio">
            <input
              type="radio"
              :id="`special_discount_structure_stepping_${props.special_discount.id}`"
              v-model="form.special_discount.structure"
              value="stepping"
            />
            {{ $t("special_discount.structure.stepping") }}
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="flex items-center gap-5 py-3"
          v-if="isUnitPriceUnifiedDiscount"
        >
          <label :for="`special_discount_structure_config_unit_price_${props.special_discount.id}`" class="">
            {{ $t("special_discount.structure_config.unit_price") }}
          </label>
          <input
            v-model="form.special_discount.structure_config.unit_price"
            type="number"
            min="0"
            @wheel="$event.target.blur()"
            class="flex-1 input"
            placeholder="0.000000"
            :id="`special_discount_structure_config_unit_price_${props.special_discount.id}`"
          />
        </div>
        <div
          class="flex items-center gap-5 py-3"
          v-if="isPercentageUnifiedDiscount"
        >
          <label for="pricing_item_structure_config_percentage" class="">
            {{ $t("special_discount.structure_config.percentage") }}
          </label>
          <span class="flex items-center gap-1">
            -
            <input
              v-model="form.special_discount.structure_config.percentage"
              type="number"
              min="0"
              @wheel="$event.target.blur()"
              class="flex-1 input"
              placeholder="0.00"
              id="pricing_item_structure_config_percentage"
            />
            %</span
          >
        </div>
        <StepConfig
          v-if="discountStructure === 'stepping'"
          :category="discountCategory"
          :structure_config="form.special_discount.structure_config"
          @submit="modifyStructureConfig"
        />
      </dd>
    </dl>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
import Condition from "./_condition.vue";
import StepConfig from "./_step_config.vue";
import Step from "./_step.vue";
const props = defineProps({
  special_discount: Object,
  options_for_select: Object,
});

const form = useForm({
  special_discount: props.special_discount,
});

const operatorOptions = ref(
  props.options_for_select.discount_operators
);

const modifyCondition = (condition, index) => {
  form.special_discount.conditions[index] = condition;
};

const addCondition = () => {
  form.special_discount.conditions.push({
    target: 'default',
    operator: "including",
    content: null,
  });
};

const removeCondition = (index) => {
  form.special_discount.conditions.splice(index, 1);
};

const discountCategory = computed(() => form.special_discount.category);
const discountStructure = computed(() => form.special_discount.structure);

const isUnitPriceUnifiedDiscount = computed(() => {
  return discountCategory.value === "unit_price" && discountStructure.value === "unified";
});

const isPercentageUnifiedDiscount = computed(() => {
  return discountCategory.value === "percentage" && discountStructure.value === "unified";
});

const modifyStructureConfig = (config) => {
  form.special_discount.structure_config = config;
};

const emit = defineEmits(['submit', 'validate']);
onMounted(() => {
  watch(discountCategory, (value) => {
    delete form.special_discount.structure_config.target_item;
    switch (value) {
      case "unit_price":
        delete form.special_discount.structure_config.percentage;
        break;
      case "percentage":
        delete form.special_discount.structure_config.unit_price;
        break;
    }
  });
  watch(discountStructure, (value) => {
    switch (value) {
      case "unified":
        delete form.special_discount.structure_config.steps;
        break;
      case "stepping":
        delete form.special_discount.structure_config.unit_price;
        delete form.special_discount.structure_config.percentage;
        break;
    }
  });

  watch(form.special_discount, (value) => {
    emit('submit', value);
  });
});
</script>
