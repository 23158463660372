<template>
  <div id="info-content" role="tabpanel" aria-labelledby="info-tab">
    <dl class="grid grid-cols-9 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_main_category">{{
          $t("activerecord.attributes.product.main_category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-8 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.cloud_account.status"
            :options="props.options_for_select.statuses"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`cloud_account.status.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`cloud_account.status.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <div class="flex items-center gap-2">
          <label for="cloud_account_id" class="ml-3 required">{{
            $t("activerecord.attributes.cloud_account.account_id")
          }}</label>
          <span>
            <input
              type="text"
              class="input"
              id="cloud_account_id"
              v-model="form.cloud_account.account_id"
              :class="{
                // error: !form.cloud_account.account_id,
              }"
            />
          </span>
        </div>
      </dd>

      <dt class="flex items-start col-span-1">
        <label class="ml-3 required" for="product_main_category">{{
          $t("activerecord.attributes.product.main_category")
        }}</label>
      </dt>
      <dd class="flex flex-wrap items-center justify-start col-span-8 gap-5">
        <div class="flex items-center gap-2">
          <multiselect
            v-model="productMainCategory"
            :options="productMainCategoryOptions"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="flex-1"
          >
            <template #singleLabel="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>
        <div class="flex items-center gap-2">
          <label for="product_summary_name" class="ml-3 required">{{
            $t("activerecord.attributes.product.summary_name")
          }}</label>

          <multiselect
            v-model="productSummaryName"
            :options="productSummaryOptions"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :placeholder="$t('helpers.select')"
            :max-height="120"
            :disabled="!productMainCategory"
            class="flex-1"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>
        <div class="flex items-center gap-2">
          <label for="product_name" class="ml-3 required">{{
            $t("activerecord.attributes.product.name")
          }}</label>
          <multiselect
            v-model="productName"
            :options="productNameOptions"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :placeholder="$t('helpers.select')"
            :max-height="120"
            :disabled="!productSummaryName"
            class="flex-1"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>
        <div class="flex items-center gap-2">
          <label for="product_name" class="ml-3 required">{{
            $t("activerecord.attributes.supplier/contract.name")
          }}</label>

          <multiselect
            v-model="contractName"
            :options="contractNameOptions"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :placeholder="$t('helpers.select')"
            :max-height="120"
            :disabled="!productName"
            class="flex-1"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>

        <div class="flex items-center gap-2">
          <label for="product_name" class="ml-3 required">{{
            $t("supplier/contract.sub_nav.pricing_item")
          }}</label>
          <multiselect
            v-model="pricingItem"
            :options="pricingItemOptions"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :placeholder="$t('helpers.select')"
            :max-height="120"
            :disabled="!contractName"
            class="flex-1"
          >
            <template #singleLabel="props">
              {{ props.option.name }}
            </template>
            <template #option="props">
              {{ props.option.name }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="cloud_account_name">{{
          productSummaryName === "gcp"
            ? $t("activerecord.attributes.cloud_account.name")
            : $t("activerecord.attributes.cloud_account.primary_email")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-8 gap-5 w-max">
        <input
          type="text"
          class="input"
          id="cloud_account_name"
          v-model="form.cloud_account.email_name"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="cloud_account_start_at">{{
          $t("activerecord.attributes.cloud_account.start_at")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-8 gap-3 w-max">
        <DatePicker
          :current_date="form.cloud_account.start_at"
          @submit="modifyStartAt"
        />
        <div class="flex items-center gap-2">
          <label class="ml-3" for="cloud_account_end_at">{{
            $t("activerecord.attributes.cloud_account.end_at")
          }}</label>

          <DatePicker
            :disabled="!props.cloud_account.id"
            :current_date="form.cloud_account.end_at"
            @submit="modifyEndAt"
          />
        </div>
        <div class="flex items-center gap-2">
          <label class="ml-3" for="cloud_account_end_way">{{
            $t("activerecord.attributes.cloud_account.end_way")
          }}</label>
          <div>
            <multiselect
              v-model="form.cloud_account.end_way"
              :options="props.options_for_select.end_ways"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              :max-height="120"
              :placeholder="$t('helpers.select')"
              class="min-w-[100px]"
              :disabled="!form.cloud_account.end_at"
            >
              <template #singleLabel="props">
                {{ $t(`cloud_account.end_way.${props.option}`) }}
              </template>
              <template #option="props">
                {{ $t(`cloud_account.end_way.${props.option}`) }}
              </template>
              <template #caret>
                <IconSvg
                  icon="arrow_down"
                  class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                ></IconSvg>
              </template>
            </multiselect>
          </div>
        </div>
        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>

        <label for="cloud_account_testing" class="custom-checkbox">
          <input type="checkbox" id="cloud_account_testing" v-model="testing" />
          {{ $t("activerecord.attributes.cloud_account.testing") }}
          <span class="checkmark"></span>
        </label>

        <div class="flex items-center gap-2">
          <label class="ml-3" for="cloud_account_testing_start_at">{{
            $t("activerecord.attributes.cloud_account.testing_start_at")
          }}</label>

          <DatePicker
            :disabled="!testing"
            :current_date="form.cloud_account.testing_start_at"
            @submit="modifyTestingStartAt"
          />
        </div>

        <div class="flex items-center gap-2">
          <label class="ml-3" for="cloud_account_testing_end_at">{{
            $t("activerecord.attributes.cloud_account.testing_end_at")
          }}</label>

          <DatePicker
            :disabled="!testing"
            :current_date="form.cloud_account.testing_end_at"
            @submit="modifyTestingEndAt"
          />
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="cloud_account_amount_currency">{{
          $t("activerecord.attributes.cloud_account.amount_currency")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-8 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.cloud_account.amount_currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="min-w-[100px]"
          >
            <template #singleLabel="props">
              {{ $t(`cloud_account.amount_currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`cloud_account.amount_currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <div class="flex items-center gap-2">
          <label for="cloud_account_amount" class="ml-3">{{
            $t("activerecord.attributes.cloud_account.amount")
          }}</label>
          <span>
            <input
              type="text"
              class="input"
              id="cloud_account_amount"
              v-model="form.cloud_account.amount"
            />
          </span>
        </div>
      </dd>
      <dd class="flex items-center justify-between col-span-9 gap-5 w-max">
        <label for="cloud_account_hide_origin_credit" class="custom-checkbox">
          <input
            type="checkbox"
            id="cloud_account_hide_origin_credit"
            v-model="form.cloud_account.hide_origin_credit"
          />
          {{ $t("activerecord.attributes.cloud_account.hide_origin_credit") }}
          <span class="checkmark"></span>
        </label>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <div class="flex items-center gap-2 mt-5">
      <h4 class="text-xl font-bold">客戶合約關連</h4>
      <button type="button" class="btn btn-primary btn-xs">新增關聯</button>
    </div>
  </div>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, watch, onMounted, computed } from "vue";

const props = defineProps({
  cloud_account: {
    type: Object,
    required: true,
  },
  options_for_select: Object,
});
const form = useForm({
  cloud_account: {
    status: props.cloud_account.status,
    account_id: props.cloud_account.account_id,
    email_name: props.cloud_account.email_name,
    amount_currency: props.cloud_account.amount_currency,
    amount: props.cloud_account.amount,
    hide_origin_credit: props.cloud_account.hide_origin_credit,
    start_at: props.cloud_account.start_at,
    end_at: props.cloud_account.end_at,
    end_way: props.cloud_account.end_way,
    testing: props.cloud_account.testing,
    testing_start_at: props.cloud_account.testing_start_at,
    testing_end_at: props.cloud_account.testing_end_at,
  },
});

const findPricingItemById = (id) => {
  for (const product of props.options_for_select.pricing_items) {
    for (const contract of product.contracts) {
      const pricing_item = contract.pricing_items.find(
        (item) => item.id === id
      );
      if (pricing_item) {
        return {
          pricing_item: {
            ...pricing_item,
          },
          product: product,
          contract: contract,
        };
      }
    }
  }
  return null;
};

const selectedPricingItemInfo = ref(
  props.cloud_account.supplier_pricing_item_ids
    ? findPricingItemById(props.cloud_account.supplier_pricing_item_ids[0])
    : null
);

const nestedPricingItems = computed(() => {
  const result = {};

  props.options_for_select.pricing_items.forEach((product) => {
    if (!result[product.main_category]) {
      result[product.main_category] = {};
    }

    if (!result[product.main_category][product.summary_name]) {
      result[product.main_category][product.summary_name] = {};
    }

    if (!result[product.main_category][product.summary_name][product.name]) {
      result[product.main_category][product.summary_name][product.name] = {
        contracts: {},
      };
    }

    product.contracts.forEach((contract) => {
      if (
        !result[product.main_category][product.summary_name][product.name]
          .contracts[[contract.name]]
      ) {
        result[product.main_category][product.summary_name][
          product.name
        ].contracts[contract.name] = {
          pricing_items: [],
        };
      }

      contract.pricing_items.forEach((item) => {
        result[product.main_category][product.summary_name][
          product.name
        ].contracts[contract.name].pricing_items.push(item);
      });
    });
  });

  return result;
});

const productMainCategory = computed(() => {
  return selectedPricingItemInfo.value
    ? selectedPricingItemInfo.value.product.main_category
    : "cloud";
});

const productMainCategoryOptions = computed(() => {
  return Object.keys(nestedPricingItems.value);
});

const productSummaryOptions = computed(() => {
  return Object.keys(nestedPricingItems.value[productMainCategory.value]);
});
const productSummaryName = ref(
  selectedPricingItemInfo.value
    ? selectedPricingItemInfo.value.product.summary_name
    : ""
);

const productNameOptions = computed(() => {
  return productSummaryName.value
    ? Object.keys(
        nestedPricingItems.value[productMainCategory.value][
          productSummaryName.value
        ]
      )
    : [];
});

const productName = ref(
  selectedPricingItemInfo.value
    ? selectedPricingItemInfo.value.product.name
    : ""
);

const contractNameOptions = computed(() => {
  return productName.value
    ? Object.keys(
        nestedPricingItems.value[productMainCategory.value][
          productSummaryName.value
        ][productName.value].contracts
      )
    : [];
});

const contractName = ref(
  selectedPricingItemInfo.value
    ? selectedPricingItemInfo.value.contract.name
    : ""
);

const pricingItemOptions = computed(() => {
  return contractName.value
    ? nestedPricingItems.value[productMainCategory.value][
        productSummaryName.value
      ][productName.value].contracts[contractName.value].pricing_items
    : [];
});

const pricingItem = ref(
  selectedPricingItemInfo.value
    ? selectedPricingItemInfo.value.pricing_item
    : ""
);

const modifyStartAt = (value) => {
  form.cloud_account.start_at = value;
};

const modifyEndAt = (value) => {
  form.cloud_account.end_at = value;
};

const modifyTestingStartAt = (value) => {
  form.cloud_account.testing_start_at = value;
};

const modifyTestingEndAt = (value) => {
  form.cloud_account.testing_end_at = value;
};

const testing = ref(props.cloud_account.testing);

const emit = defineEmits(["submit"]);
onMounted(() => {
  watch(productMainCategory, (value) => {
    productSummaryName.value = null;
    productName.value = null;
  });

  watch(productSummaryName, (value) => {
    productName.value = null;
  });

  watch(productName, (value) => {
    contractName.value = null;
  });

  watch(contractName, (value) => {
    pricingItem.value = null;
  });

  watch(pricingItem, (value) => {
    // change to array because of has_many
    if (value) {
      form.cloud_account.supplier_pricing_item_ids = [value.id];
    } else {
      form.cloud_account.supplier_pricing_item_ids = null;
    }
  });

  // clear testing start at and end at when testing is false
  watch(testing, (value) => {
    if (!value) {
      form.cloud_account.testing_start_at = null;
      form.cloud_account.testing_end_at = null;
    }
    form.cloud_account.testing = value;
  });

  watch(form.cloud_account, () => {
    emit("submit", form.cloud_account);
  });
});
</script>
