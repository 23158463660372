<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex flex-col items-start justify-between gap-5 mx-3 mb-5">
          <div class="flex items-center gap-1">
            <label for="user_group_name" class="required">
              {{ $t("activerecord.attributes.user_group.name") }}</label
            >
            <div>
              <input
                type="text"
                class="input"
                id="user_group_name"
                v-model="form.user_group.name"
                :class="{
                  // error: !form.user_group.name,
                }"
              />
            </div>
          </div>
          <div class="flex items-center gap-1">
            <label for="description" class="form-label">{{
              $t("activerecord.attributes.user_group.description")
            }}</label>
            <div>
              <input
                type="text"
                class="input"
                id="description"
                v-model="form.user_group.description"
              />
            </div>
          </div>
        </div>

        <hr class="border-gray-40" />

        <TabNav :tab-list="sub_menu_lists">
          <TabUser
            @submit="modifyUserGroup"
            :user_group="form.user_group"
            :users="[]"
            :all_users="all_users"
          />
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="validate(form.user_group)"
          :back-url="'/user_groups'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import FormAction from "@/components/form_action.vue";
import TabUser from "./_tab_user.vue";
import { validate } from "./validate";

const props = defineProps({
  user_group: {
    name: String,
    description: String,
  },
  all_users: {
    type: Array,
    required: true,
  },
  errors: Object,
  sub_menu_lists: Array,
});

const form = useForm({
  user_group: {
    name: "",
    description: "",
    user_ids: [],
  },
});

const formSubmit = () => {
  form.post("/user_groups");
};

const modifyUserGroup = (new_value) => {
  Object.assign(form.user_group, new_value);
};

</script>
