<template>
  <div class="*:py-5 divide-y">
    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-center col-span-1">
        <label :for="`min_amount_name_${props.min_amount.id}`" class="ml-3 required">
          {{ $t("activerecord.attributes.client/min_amount.name") }}
        </label>
      </dt>
      <dd class="flex items-center col-span-5">
        <input
          v-model="form.min_amount.name"
          type="text"
          class="input"
          :id="`min_amount_name_${props.min_amount.id}`"
        />
      </dd>
    </dl>
    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-start col-span-1">
        <label :for="`min_amount_conditions_${props.min_amount.id}`" class="ml-3">
          {{
            $t("activerecord.attributes.client/min_amount.conditions")
          }}
        </label>
      </dt>
      <dd class="flex flex-col items-start col-span-11 gap-2">
        <Condition
          v-for="(condition, index) in form.min_amount.conditions"
          :condition="condition"
          :index="index"
          :target_options="options_for_select.min_amount_targets"
          :operator_options="operatorOptions"
          @submit="modifyCondition"
          @removeCondition="removeCondition"
        />
        <button @click="addCondition" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="size-4"></IconSvg>
          {{
            $t("helpers.new", {
              model: $t(
                "activerecord.attributes.client/min_amount.conditions"
              ),
            })
          }}
        </button>
      </dd>
    </dl>

    <dl class="grid grid-cols-12 gap-5">
      <dt class="flex items-center col-span-1">
        <label :for="`min_amount_currency_${props.min_amount.id}`" class="ml-3 required operation_system">
          {{
            $t(
              "activerecord.attributes.client/min_amount.currency"
            )
          }}
        </label>
      </dt>
      <dd class="flex flex-col items-start col-span-1 gap-2">
        <span class="flex items-center gap-1">
          {{ props.min_amount.currency }}
        </span>
      </dd>
      <dt class="flex items-center col-span-1">
        <label :for="`min_amount_price_${props.min_amount.id}`" class="ml-3 required">
          {{
            $t(
              "activerecord.attributes.client/min_amount.price"
            )
          }}
        </label>
      </dt>
      <dd class="flex flex-col items-start col-span-9 gap-2">
        <input
          v-model="form.min_amount.price"
          type="number"
          min="0"
          @wheel="$event.target.blur()"
          class="flex-1 input"
          placeholder="0.00"
          :id="`min_amount_price_${props.min_amount.id}`"
        />
      </dd>
    </dl>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
import Condition from "./_condition.vue";

const props = defineProps({
  min_amount: Object,
  options_for_select: Object,
});

const form = useForm({
  min_amount: props.min_amount,
});

const operatorOptions = ref(
  props.options_for_select.discount_operators
);

const modifyCondition = (condition, index) => {
  form.min_amount.conditions[index] = condition;
};

const addCondition = () => {
  form.min_amount.conditions.push({
    target: 'default',
    operator: "including",
    content: null,
  });
};

const removeCondition = (index) => {
  form.min_amount.conditions.splice(index, 1);
};


const emit = defineEmits(['submit', 'validate']);
onMounted(() => {
  watch(form.min_amount, (value) => {
    emit('submit', value);
  });
});
</script>
