<template>
  <div class="space-5">
    <dl class="grid grid-cols-8 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_main_category">{{
          $t("activerecord.attributes.product.main_category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.product.main_category"
            :options="props.options_for_select.main_categories"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>
        <label for="product_summary_name" class="ml-3 required">{{
          $t("activerecord.attributes.product.summary_name")
        }}</label>

        <div class="flex items-center gap-1">
          <label for="product_summary_name"
            >{{ $t(`product.main_category.${form.product.main_category}`) }} -
          </label>
          <p>
            <input
              type="text"
              class="input"
              id="product_summary_name"
              v-model="form.product.summary_name"
              :class="{
                error: errors.summary_name,
              }"
              @blur="!!form.product.summary_name ? (errors.summary_name = null) : errors.summary_name"
            />
            <p v-if="errors.summary_name" class="text-right text-warning">{{
              errors.summary_name.join("、")
            }}</p>
          </p>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>

        <label for="product_name" class="ml-3 required">{{
          $t("activerecord.attributes.product.name")
        }}</label>

        <div class="flex items-center gap-1">
          <label for="product_name"
            >{{ $t(`product.main_category.${form.product.main_category}`) }} -
            {{ form.product.summary_name }} -
          </label>
          (
          <p>
            <input
              type="text"
              class="input"
              id="product_name"
              v-model="form.product.name"
              :class="{
                error: errors.name,
              }"
              @blur="!!form.product.name ? (errors.name = null) : errors.name"
            />
            <p v-if="errors.name" class="text-right text-warning">{{
              errors.name.join("、")
            }}</p>
          </p>
          )
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_category">{{
          $t("activerecord.attributes.product.category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <label for="category_idc" class="custom-radio">
          <input
            type="radio"
            id="category_idc"
            v-model="form.product.category"
            value="idc"
          />
          {{ $t("product.category.idc") }}
          <span class="checkmark"></span>
        </label>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_subsidiary">{{
          $t("activerecord.attributes.product.subsidiary")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <label for="subsidiary_dgc_tw" class="custom-radio">
          <input
            type="radio"
            id="subsidiary_dgc_tw"
            v-model="form.product.subsidiary"
            value="dgc_tw"
          />
          {{ $t("product.subsidiary.dgc_tw") }}
          <span class="checkmark"></span>
        </label>
        <label for="subsidiary_dgc_hk" class="custom-radio">
          <input
            type="radio"
            id="subsidiary_dgc_hk"
            v-model="form.product.subsidiary"
            value="dgc_hk"
          />
          {{ $t("product.subsidiary.dgc_hk") }}
          <span class="checkmark"></span>
        </label>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required operation_system" for="product_supplier">{{
          $t("activerecord.attributes.product.supplier")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <p>
          <input
            type="text"
            class="input"
            id="product_supplier"
            v-model="form.product.supplier"
            :class="{
              error: errors.supplier,
            }"
            @blur="!!form.product.supplier ? (errors.supplier = null) : errors.supplier"
          />
          <p v-if="errors.supplier" class="text-right text-warning">{{
            errors.supplier.join("、")
          }}</p>
        </p>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_owner">{{
          $t("activerecord.attributes.product.owner")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div class="flex flex-col items-end justify-between">
          <multiselect
            v-model="selectedOwner"
            :options="props.options_for_select.owners"
            :show-labels="false"
            :allow-empty="false"
            :max-height="120"
            class="min-w-[300px]"
            :placeholder="$t('helpers.select')"
            :class="errors.owner ? 'error' : ''"
            @select="modifyOwner"
          >
            <template #singleLabel="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #option="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <p v-if="errors.owner" class="text-right text-warning">{{
            errors.owner.join("、")
          }}</p>
        </div>
        <label for="product_owner_unit" class="ml-3 required">
          {{ $t("activerecord.attributes.product.owner_unit") }}</label
        >

        <label for="product_owner_unit" class="custom-radio">
          <input
            type="radio"
            id="product_owner_unit"
            v-model="form.product.owner_unit"
            value="product_service"
          />
          {{ $t(`product.owner_unit.${form.product.owner_unit}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <dl class="grid grid-cols-6 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_primary_contact_email">{{
          $t("activerecord.attributes.product.primary_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <p>
          <input
            class="input"
            id="product_primary_contact_email"
            type="email"
            v-model="form.product.primary_contact.email"
            :class="{
              error: errors.primary_contact_email,
            }"
            @blur="!!form.product.primary_contact.email ? (errors.primary_contact_email = null) : errors.primary_contact_email"
          />
          <p v-if="errors.primary_contact_email" class="text-right text-warning">{{
            errors.primary_contact_email.join("、")
          }}</p>
        </p>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_billing_contact_email">{{
          $t("activerecord.attributes.product.billing_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <p>
          <input
            class="input"
            id="product_billing_contact_email"
            type="email"
            v-model="form.product.billing_contact.email"
            :class="{
              error: errors.billing_contact_email,
            }"
            @blur="!!form.product.billing_contact.email ? (errors.billing_contact_email = null) : errors.billing_contact_email"
          />
          <p v-if="errors.billing_contact_email" class="text-right text-warning">{{
            errors.billing_contact_email.join("、")
          }}</p>
        </p>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_primary_contact_name">{{
          $t("activerecord.attributes.product.primary_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <p>
          <input
            class="input"
            id="product_primary_contact_name"
            type="text"
            v-model="form.product.primary_contact.name"
            :class="{
              error: errors.primary_contact_name,
            }"
            @blur="!!form.product.primary_contact.name ? (errors.primary_contact_name = null) : errors.primary_contact_name"
          />
          <p v-if="errors.primary_contact_name" class="text-right text-warning">{{
            errors.primary_contact_name.join("、")
          }}</p>
        </p>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_billing_contact_name">{{
          $t("activerecord.attributes.product.billing_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <p>
          <input
            class="input"
            id="product_billing_contact_name"
            type="text"
            v-model="form.product.billing_contact.name"
            :class="{
              error: errors.billing_contact_name,
            }"
            @blur="!!form.product.billing_contact.name ? (errors.billing_contact_name = null) : errors.billing_contact_name"
          />
          <p v-if="errors.billing_contact_name" class="text-right text-warning">{{
            errors.billing_contact_name.join("、")
          }}</p>
        </p>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_primary_contact_phone">{{
          $t("activerecord.attributes.product.primary_contact_phone")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_primary_contact_phone"
          type="text"
          v-model="form.product.primary_contact.phone"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_billing_contact_phone">{{
          $t("activerecord.attributes.product.billing_contact_phone")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_billing_contact_phone"
          type="text"
          v-model="form.product.billing_contact.phone"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_origin_currency">{{
          $t("activerecord.attributes.product.origin_currency")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-5 gap-5">
        <div class="flex flex-col items-end justify-between">
          <multiselect
            v-model="form.product.origin_currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            :class="errors.origin_currency ? 'error' : ''"
            @select="modifyOriginCurrency"
          >
            <template #singleLabel="props">
              {{ $t(`product.origin_currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.origin_currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <p v-if="errors.origin_currency" class="text-right text-warning">{{
            errors.origin_currency.join("、")
          }}</p>
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_trade_currency">{{
          $t("activerecord.attributes.product.trade_currency")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-5 gap-5">
        <div class="flex flex-col items-end justify-between">
          <multiselect
            v-model="form.product.trade_currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            :class="errors.trade_currency ? 'error' : ''"
            @select="modifyTradeCurrency"
          >
            <template #singleLabel="props">
              {{ $t(`product.trade_currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.trade_currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <p v-if="errors.trade_currency" class="text-right text-warning">{{
            errors.trade_currency.join("、")
          }}</p>
        </div>
        <label class="ml-3 required" for="product_payment_term">{{
          $t("activerecord.attributes.product.payment_term")
        }}</label>
        <div class="flex items-center gap-1">
          <multiselect
            v-model="form.product.payment_term"
            :options="props.options_for_select.payment_terms"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="!min-w-[auto]"
            :class="errors.payment_term ? 'error' : ''"
            @select="modifyPaymentTerm"
          >
            <template #singleLabel="props">
              {{ $t(`product.payment_term.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.payment_term.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <p v-if="errors.payment_term" class="text-right text-warning">{{
            errors.payment_term.join("、")
          }}</p>
          <p>
            <input
              class="input"
              id="product_payment_term_other"
              type="text"
              v-model="form.product.payment_term_other"
              v-if="form.product.payment_term === 'other'"
              :class="{
                error: errors.payment_term_other,
              }"
              @blur="!!form.product.payment_term_other ? (errors.payment_term_other = null) : errors.payment_term_other"
            />
            <p v-if="errors.payment_term_other" class="text-right text-warning">{{
              errors.payment_term_other.join("、")
            }}</p>
          </p>
        </div>
        <label class="ml-3 required" for="product_exchange_rate">{{
          $t("activerecord.attributes.product.exchange_rate")
        }}</label>
        <div class="flex items-center gap-1">
          <multiselect
            v-model="form.product.exchange_rate"
            :options="props.options_for_select.exchange_rates"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            :class="errors.exchange_rate ? 'error' : ''"
            @select="modifyExchangeRate"
          >
            <template #singleLabel="props">
              {{ $t(`product.exchange_rate.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.exchange_rate.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <p v-if="errors.exchange_rate" class="text-right text-warning">{{
            errors.exchange_rate.join("、")
          }}</p>
          <p>
            <input
              class="input"
              id="product_exchange_rate_fix"
              type="number"
              @wheel="$event.target.blur()"
              min="0"
              step="0.000001"
              v-model="form.product.exchange_rate_fix"
              v-if="form.product.exchange_rate === 'fix_rate'"
              :class="{
                error: errors.exchange_rate_fix,
              }"
              @blur="!!form.product.exchange_rate_fix ? (errors.exchange_rate_fix = null) : errors.exchange_rate_fix"
            />
            <p v-if="errors.exchange_rate_fix" class="text-right text-warning">{{
              errors.exchange_rate_fix.join("、")
            }}</p>
          </p>
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_comment">{{
          $t("activerecord.attributes.product.comment")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-5 gap-5">
        <input
          class="input"
          id="product_comment"
          v-model="form.product.comment"
        />
      </dd>
    </dl>
  </div>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  options_for_select: Object,
  errors: {
    type: Object,
    default: {},
  },
});
const form = useForm({
  product: {
    main_category: props.product.main_category,
    category: props.product.category,
    subsidiary: props.product.subsidiary,
    owner_unit: props.product.owner_unit,
    currency: props.product.currency,
    payment_term: props.product.payment_term,
    payment_term_other: props.product.payment_term_other,
    exchange_rate: props.product.exchange_rate,
    exchange_rate_fix: props.product.exchange_rate_fix,
    supplier: props.product.supplier,
    name: props.product.name,
    summary_name: props.product.summary_name,
    billing_contact: props.product.billing_contact,
    primary_contact: props.product.primary_contact,
    origin_currency: props.product.origin_currency,
    trade_currency: props.product.trade_currency,
    owner_id: props.product.owner_id,
    comment: props.product.comment,
  },
});

const emit = defineEmits(["submit"]);
const selectedOwner = ref(props.options_for_select.owners.find(owner => owner.id === props.product.owner_id));
onMounted(() => {
  watch(form.product, () => {
    emit("submit", form.product);
  });
});

const modifyOwner = (value) => {
  form.product.owner_id = value;
  if (value) {
    props.errors.owner = null;
  }
};

const modifyOriginCurrency = (value) => {
  form.product.origin_currency = value;
  if (value) {
    props.errors.origin_currency = null;
  }
};

const modifyTradeCurrency = (value) => {
  if (value) {
    props.errors.trade_currency = null;
  }
};

const modifyPaymentTerm = (value) => {
  if (value) {
    props.errors.payment_term = null;
    props.errors.payment_term_other = null;
  }
};

const modifyExchangeRate = (value) => {
  if (value) {
    props.errors.exchange_rate = null;
    props.errors.exchange_rate_fix = null;
  }
};
</script>
