<template>
  <div class="mb-3">
    <SubNav :sub-nav-list="index_menu_lists"></SubNav>
  </div>
  <div class="card">
    <DataTable :resources="organizations" :paginate="paginate">
      <template #actions>
        <Link href="/organizations/new" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.organization") }) }}
        </Link>
      </template>
      <template #thead>
        <DataTableHead id="checkbox" :disableOrder="true" class="w-5">
          <label for="organization-select-all" class="custom-checkbox">
            <input
              type="checkbox"
              id="organization-select-all"
              v-model="selectAll"
            />
            <span class="checkmark checkmark-sm"></span>
          </label>
        </DataTableHead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
        <DataTableHead
          id="actions"
          :text="$t('helpers.actions')"
          :disableOrder="true"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          <span class="flex items-center gap-1">
            <label
              :for="`organization-select-${resource.id}`"
              class="custom-checkbox"
            >
              <input
                type="checkbox"
                :id="`organization-select-${resource.id}`"
                v-model="download_ids"
                :value="resource.id"
              />
              <span class="checkmark checkmark-sm"></span>
            </label>
          </span>
        </td>
        <td>
          <Link class="text-gray-60" :href="`/organizations/${resource.id}/edit`">{{
            resource.name
          }}</Link>
        </td>
        <td>{{ resource.customer_name }}</td>
        <td>{{ resource.status }}</td>
        <td>{{ resource.owner }}</td>
        <td>{{ resource.comment }}</td>
        <td>{{ resource.created_at }}</td>
        <td></td>
      </template>
      <template #footer-actions>
        <button
          :disabled="download_ids.length === 0"
          type="button"
          class="btn btn-sm btn-primary"
          @click="downloadOrganizations"
        >
          <IconSvg icon="download" class="w-4 h-4 fill-white"></IconSvg>
          {{ $t("helpers.download", { text: $t("organization.index.download") }) }}
        </button>
        <JsonCSV
          class="hidden"
          ref="csvDownloadBtn"
          :data="csvData"
          :name="`${$t('nav.organization')}.csv`"
        />
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import JsonCSV from 'vue-json-csv'

const props = defineProps({
  index_menu_lists: {
    type: Array,
    required: true,
  },
  organizations: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
  authenticity_token: {
    type: String,
    required: true,
  },
});

const theads = [
  {
    id: "name",
    i18n_key: "activerecord.attributes.organization.name",
    defaultOrderColumn: true,
    disableOrder: false,
  },
  {
    id: "customer_name",
    i18n_key: "activerecord.attributes.organization.customer_name",
    disableOrder: true,
  },
  {
    id: "status",
    i18n_key: "activerecord.attributes.organization.status",
    disableOrder: true,
  },
  {
    id: "owner",
    i18n_key: "activerecord.attributes.organization.owner",
    disableOrder: true,
  },
  {
    id: "comment",
    i18n_key: "activerecord.attributes.organization.comment",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "activerecord.attributes.organization.created_at",
    disableOrder: false,
  }
];

const download_ids = ref([]);
const csvData = ref([])
const csvDownloadBtn = ref(null)
const downloadOrganizations = () => {
  fetch('/organizations/download', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': props.authenticity_token,
    },
    body: JSON.stringify({
      ids: download_ids.value,
    }),
  })
  .then(response => response.json())
  .then(data => {
    csvData.value = data
    nextTick(() => {
      document.querySelector(`#${csvDownloadBtn.value.$el.id}`).click()
    })
  })
};

const selectAll = ref(false);

onMounted(() => {
  if (download_ids.value.length === props.organizations.length) {
    selectAll.value = true;
  } else if (download_ids.value.length > 0) {
    selectAll.value = false;
  } else {
    selectAll.value = false;
  }

  watch(selectAll, (newVal) => {
    if (newVal) {
      download_ids.value = props.organizations.map((organization) => organization.id);
    } else {
      download_ids.value = [];
    }
  });

  watch(
    () => download_ids.value,
    (newVal) => {
      const checkedIds = newVal;
      const element = document.querySelector("#organization-select-all");

      if (checkedIds.length === 0) {
        element.indeterminate = false;
        selectAll.value = false;
      } else if (checkedIds.length === props.organizations.length) {
        element.indeterminate = false;
        selectAll.value = true;
      } else {
        element.indeterminate = true;
      }
    }
  );
});
</script>
