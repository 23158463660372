<template>
  <ConfirmModal
    :modal-cancel-text="$t('helpers.cancel')"
    :modal-title="$t('activerecord.models.min_amount')"
    modalSubmitClass="btn btn-sm btn-primary"
    :modal-submit-text="$t('helpers.confirm')"
    modalSize="xl"
    @submit="editMinAmount(form.min_amount.id)"
    class="grid block w-full grid-cols-12 gap-2 px-5 py-4 bg-white rounded shadow-lg"
    :class="{
      hidden: form.min_amount._destroy == 1 || !form.min_amount.name.includes(props.search),
    }"
    :key="instance.uid"
    :prevent-submit="!validate(tempEditedMinAmount)"
  >
    <template #button>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{ $t("activerecord.attributes.client/min_amount.name") }}
      </div>
      <div class="self-center col-span-4 text-gray-500">
        {{ form.min_amount.name }}
      </div>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{ $t("activerecord.attributes.client/min_amount.conditions") }}
      </div>
      <div class="self-center col-span-2 text-gray-500 truncate">
        <template v-if="form.min_amount.conditions.length > 1">
          {{
            $t(
              "supplier/pricing_item.min_amount.form.multiple_conditions"
            )
          }}({{ form.min_amount.conditions.length }})
        </template>
        <template v-if="form.min_amount.conditions.length === 1">
          {{ $t(
              `special_discount.conditions.target.${form.min_amount.conditions[0].target}`
            ) }}
          {{
            $t(
              `client/min_amount.conditions.operator.${form.min_amount.conditions[0].operator}`
            )
          }}
          {{ form.min_amount.conditions[0].content }}
        </template>
      </div>
      <div class="self-center col-span-1 font-bold text-gray-700">
        {{
          $t(
            "activerecord.attributes.client/min_amount.price"
          )
        }}
      </div>
      <div class="self-center col-span-2 text-gray-500">
        {{ form.min_amount.price }} {{ props.min_amount.currency }}
      </div>
      <div class="flex justify-end col-span-auto">
        <button @click.stop="removeMinAmount(form.min_amount.id)" type="button">
          <IconSvg icon="delete" class="size-6 fill-red-500"></IconSvg>
        </button>
      </div>
    </template>

    <template #default>
      <MinAmountForm
        :min_amount="form.min_amount"
        :options_for_select="options_for_select"
        @submit="changeTempEditedMinAmount"
      />
    </template>
  </ConfirmModal>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
import MinAmountForm from "./_form.vue";
import { validate } from "./validate";

const props = defineProps({
  min_amount: Object,
  options_for_select: Object,
  search: String,
});

const instance = getCurrentInstance();
const form = useForm({
  min_amount: props.min_amount,
})

const emit = defineEmits(['submit', 'remove'])

const tempEditedMinAmount = ref(props.min_amount);
const changeTempEditedMinAmount = (min_amount) => {
  tempEditedMinAmount.value = min_amount;
};

const editMinAmount = () => {
  form.min_amount = tempEditedMinAmount.value
  emit('submit', form.min_amount)
};

const removeMinAmount = () => {
  form.min_amount._destroy = 1
  emit('remove', form.min_amount.id)
};

onMounted(() => {
  window.HSStaticMethods.autoInit();
});
</script>
