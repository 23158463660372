<template>
  <div class="mb-3">
    <SubNav :sub-nav-list="index_menu_lists"></SubNav>
  </div>

  <div class="card">
    <div class="pb-0 card-body">
      <p>
        {{ $t('sync_histories.synced_at') }}
        {{ dateFormat(synced_at) }}
      </p>
      <SubNav :sub-nav-list="index_sub_menu_lists" :asButton="false"></SubNav>
    </div>

    <DataTable :resources="temporaryOrganizations" :paginate="paginate">
      <template #actions>
        <Link href="#" class="btn btn-sm btn-primary">
          <IconSvg icon="loading" class="w-4 h-4 fill-white"></IconSvg>
          {{ $t('sync_histories.manual_update') }}
        </Link>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
        <DataTableHead
          id="actions"
          :text="$t('helpers.actions')"
          :disableOrder="true"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td class="operation_system">{{ resource.operation_no }}</td>
        <td class="operation_system">{{ resource.name }}
        </td>
        <td class="operation_system">{{ resource.customer_name }}</td>
        <td class="flex gap-2">
          <span class="underline text-primary">{{ $t('sync_histories.create_customer') }}</span>
          <span class="underline text-warning">{{ $t('sync_histories.not_multi_cloud') }}</span>
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  index_menu_lists: {
    type: Array,
    required: true,
  },
  index_sub_menu_lists: {
    type: Array,
    required: true,
  },
  synced_at: {
    type: String,
    required: true,
  },
  organizations: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  }
});

const theads = [
  {
    id: "operation_no",
    i18n_key: "activerecord.attributes.organization.operation_no",
    defaultOrderColumn: true,
    disableOrder: false,
  },
  {
    id: "name",
    i18n_key: "activerecord.attributes.organization.name",
    defaultOrderColumn: false,
    disableOrder: false,
  },
  {
    id: "customer_name",
    i18n_key: "activerecord.attributes.organization.customer_name",
    disableOrder: true,
  }
];

const temporaryOrganizations = ref([
  {
    id: 1,
    name: "Organization 1",
    customer_name: "Company 1",
    operation_no: "1234567890",
  },
  {
    id: 2,
    name: "Organization 2",
    customer_name: "Company 2",
    operation_no: "1234567890",
  },
  {
    id: 3,
    name: "Organization 3",
    customer_name: "Company 3",
    operation_no: "1234567890",
  },

]);
</script>
