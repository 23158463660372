<template>
  <div class="flex items-center justify-between">
    <ConfirmModal
      :modal-cancel-text="$t('helpers.cancel')"
      :modal-title="$t('activerecord.models.client/min_amount')"
      modalSubmitClass="btn btn-sm btn-primary"
      :modal-submit-text="$t('helpers.confirm')"
      modalSize="xl"
      @submit="newMinAmount"
      class="btn btn-sm btn-primary"
      :prevent-submit="!validate(tempMinAmount)"
    >
      <template #button>
        <IconSvg icon="new" class="w-4 h-4"></IconSvg>
        {{
          $t("helpers.new", {
            model: $t("activerecord.models.client/min_amount"),
          })
        }}
      </template>

      <template #default>
        <MinAmountForm
          :min_amount="new_min_amount"
          :options_for_select="options_for_select"
          @submit="addTempMinAmount"
          :key="newKey"
        />
      </template>
    </ConfirmModal>

    <div class="relative">
      <label for="search-min-amount" class="relative block">
        <IconSvg
          icon="search"
          class="w-4 h-4 fill-gray-80 with-input"
        ></IconSvg>

        <input
          v-model="searchMinAmount"
          type="text"
          id="search-min-amount"
          class="input with-icon"
          :placeholder="$t('helpers.search')"
        />
      </label>
    </div>
  </div>
  <div
    class="flex flex-col gap-5 px-5 py-3 my-5 -mx-5 border-t border-b border-gray-20 bg-gray-5"
  >
    <div v-if="minAmounts.length === 0" class="text-left text-gray-40">
      {{ $t("helpers.no_data") }}
    </div>
    <Item
      v-for="min_amount in minAmounts"
      :min_amount="min_amount"
      :options_for_select="options_for_select"
      :key="min_amount.id || `temp_${Math.random().toString(36).substring(2, 15)}`"
      :search="searchMinAmount"
      @submit="editMinAmount"
      @remove="removeMinAmount"
    ></Item>
  </div>
</template>
<script setup>
import { ref, computed, onMounted} from "vue";
import Item from "./_item.vue";
import MinAmountForm from "./_form.vue";
import { validate } from "./validate";

const props = defineProps({
  new_min_amount: Object,
  options_for_select: Object,
  min_amounts: {
    type: Array,
    default: [],
  },
});

const minAmounts = ref(props.min_amounts);

const searchMinAmount = ref('');

const tempMinAmount = ref({});
const addTempMinAmount = (min_amount) => {
  tempMinAmount.value = min_amount;
};

const emit = defineEmits(["submit"]);
const newKey = ref(0);
const newMinAmount = () => {
  minAmounts.value.push(tempMinAmount.value);
  newKey.value++;
  emit("submit", {
    min_amounts_attributes: minAmounts,
  });
};

const editMinAmount = (min_amount) => {
  const index = minAmounts.value.findIndex(
    ({ id }) => id === min_amount.id
  );

  minAmounts.value[index] = min_amount;

  emit("submit", {
    min_amounts_attributes: minAmounts,
  });
};

const removeMinAmount = (min_amount_id) => {
  minAmounts.value.find(
    ({ id }) => id === min_amount_id
  )._destroy = 1;

  emit("submit", {
    min_amounts_attributes: minAmounts.value,
  });
};

onMounted(() => {
  window.HSStaticMethods.autoInit()
});
</script>
