<template>
  <div class="flex items-center w-full gap-2">
    <multiselect
      v-model="form.condition.target"
      :options="target_options"
      :show-labels="false"
      :allow-empty="false"
      :searchable="true"
      :max-height="120"
      :placeholder="$t('helpers.select')"
      class="!min-w-[auto] max-w-[max-content]"
    >
      <template #singleLabel="props">
        {{ $t(`special_discount.conditions.target.${props.option}`) }}
      </template>
      <template #option="props">
        {{ $t(`special_discount.conditions.target.${props.option}`) }}
      </template>
      <template #caret>
        <IconSvg
          icon="arrow_down"
          class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
        ></IconSvg>
      </template>
    </multiselect>

    <multiselect
      v-model="form.condition.operator"
      :options="props.operator_options"
      :show-labels="false"
      :searchable="false"
      :allow-empty="false"
      :max-height="120"
      class="!min-w-[auto] max-w-[max-content]"
    >
      <template #singleLabel="props">
        {{ $t(`client/min_amount.conditions.operator.${props.option}`) }}
      </template>
      <template #option="props">
        {{ $t(`client/min_amount.conditions.operator.${props.option}`) }}
      </template>
      <template #caret>
        <IconSvg
          icon="arrow_down"
          class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
        ></IconSvg>
      </template>
    </multiselect>

    <input
      v-model="form.condition.content"
      :placeholder="
        $t('client/min_amount.conditions.content_placeholder')
      "
      type="text"
      class="input"
      :id="`min_amount_condition_${props.index}_content`"
    />

    <button @click="removeCondition(index)" class="flex items-center">
      <IconSvg icon="delete" class="size-6 fill-warning"></IconSvg>
    </button>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps({
  condition: Object,
  index: Number,
  target_options: Array,
  operator_options: Array,
});

const form = useForm({
  condition: props.condition,
});

const emit = defineEmits(["removeCondition", "submit"]);

const removeCondition = () => {
  emit("removeCondition", props.index);
};

onMounted(() => {
  watch(form.condition, () => {
    emit("submit", form.condition, props.index);
  });
});
</script>
