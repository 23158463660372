<template>
  <div class="card">
    <div class="card-body">
      <SubNav :sub-nav-list="sub_menu_lists"></SubNav>
    </div>
    <DataTable :resources="versions" :paginate="paginate">
      <template #actions>
        <div class="flex items-center">
          <span>{{ $t("version.index.title") }}</span>
        </div>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(thead.i18n_key)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
        <DataTableHead
          id="actions"
          :text="$t('helpers.actions')"
          :disableOrder="true"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          {{ resource.same_versions.at(-1).item_type }}
        </td>
        <td>{{ resource.same_versions.at(-1).event }}</td>
        <td>{{ resource.same_versions.at(-1).user_name }}</td>
        <td>{{ resource.same_versions.at(-1).ip }}</td>
        <td>{{ resource.same_versions.at(-1).created_at }}</td>
        <td>
          <CommonModal
          class="p-0 underline btn btn-sm btn-link"
            :button-text="$t('version.index.view_more')"
            :modal-title="
              $t('activerecord.models.version')
            ">
            <dl>
              <dt class="font-bold">Request UUID</dt>
              <dd>{{ resource.same_versions.at(-1).request_uuid }}</dd>
              <dt class="font-bold">{{ $t('activerecord.attributes.version.user_name') }}</dt>
              <dd>{{ resource.same_versions.at(-1).user_name }}</dd>
              <dt class="font-bold">{{ $t('activerecord.attributes.version.created_at') }}</dt>
              <dd>{{ resource.same_versions.at(-1).created_at }}</dd>
              <dt class="font-bold">{{ $t('activerecord.attributes.version.ip') }}</dt>
              <dd>{{ resource.same_versions.at(-1).ip }}</dd>
              <dt class="font-bold">{{ $t('activerecord.attributes.version.object_changes') }}</dt>
              <dd v-for="version in [...resource.same_versions].reverse()">
                <dl>
                  <dt>{{ version.item_type }}</dt>
                  <dd><pre>{{ version.object_changes }}</pre></dd>
                </dl>
              </dd>
            </dl>
          </CommonModal>
        </td>
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  versions: Array,
  paginate: Object,
  sub_menu_lists: Array,
});

const theads = [
  {
    id: "item_type",
    i18n_key: "activerecord.attributes.version.item",
    defaultOrderColumn: false,
  },
  {
    id: "event",
    i18n_key: "activerecord.attributes.version.event",
    defaultOrderColumn: false,
  },
  {
    id: "whodunnit",
    i18n_key: "activerecord.attributes.version.user_name",
    defaultOrderColumn: false,
  },
  {
    id: "ip",
    i18n_key: "activerecord.attributes.version.ip",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "activerecord.attributes.version.created_at",
    defaultOrderColumn: true,
  },
];
</script>
